/** 共通定数  */
export const Literal = {

  /** マスク */
  MASK: '****',

}

/** Local Storage Key  */
export const LocalStorageKey = {

  /** ログイン済 */
  LOGGED: 'logged',

  /** トップページ（PC） */
  TOP_PAGE_PC: 'topPagePC',

  /** トップページ（SP） */
  TOP_PAGE_SP: 'topPageSP',

  /** 損益管理 */
  Sales: {
    /** 時間別売上 */
    TimeList: {
      SEARCH_TAX_EXCLUDE         : 'sales.timeList.searchTaxExclude',
    },

    /** 日別店舗売上 */
    MonthlyDayStoreSalesManagement: {
      SEARCH_TAX_EXCLUDE         : 'sales.monthlyDayStoreSalesManagement.searchTaxExclude',
    },
  },

  /** 勤怠管理 */
  Attend: {

    /** 勤務データ確認 */
    AttendResult: {
      SEARCH_TARGET_DATE_FROM    : 'attend.attendResult.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendResult.searchTargetDateTo',
      SEARCH_ORG_CODE            : 'attend.attendResult.searchOrgCode',
      SEARCH_CATEGORY            : 'attend.attendResult.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.attendResult.searchCategoryOption',
    },
    /** 勤務スケジュール（月次） */
    MonthlyShift: {
      SEARCH_TARGET_DATE         : 'attend.monthlyShift.searchTargetDate',
      SEARCH_VIEW_PERIOD         : 'attend.monthlyShift.searchViewPeriod',
      SEARCH_ATTEND_FLAG         : 'attend.monthlyShift.searchAttendFlag',
      SEARCH_ORG_CODE            : 'attend.monthlyShift.searchOrgCode',
      SEARCH_ORG_NAME            : 'attend.monthlyShift.searchOrgName',
      SEARCH_CATEGORY            : 'attend.monthlyShift.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.monthlyShift.searchCategoryOption',
      SEARCH_PAGE_INDEX          : 'attend.monthlyShift.searchPageIndex',
      SEARCH_PAGE_SIZE           : 'attend.monthlyShift.searchPageSize',
    },
    /** 勤怠エラーリスト */
    AttendError: {
      SEARCH_TARGET_DATE_FROM    : 'attend.attendError.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendError.searchTargetDateTo',
      SEARCH_IS_FILTER           : 'attend.attendError.searchIsFilter',
      SEARCH_ORG_CODE            : 'attend.attendError.searchOrgCode',
      SEARCH_CATEGORY            : 'attend.attendError.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.attendError.searchCategoryOption',
    },
    /** 勤怠承認 */
    AttendApplication: {
      SEARCH_TARGET_DATE_MONTH   : 'attend.attendApplication.searchTargetDateMonth',
      SEARCH_TARGET_DATE_FROM    : 'attend.attendApplication.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendApplication.searchTargetDateTo',
    },
    /** 勤怠確定 */
    AttendHeadquartersFinal: {
      SEARCH_TARGET_DATE_MONTH   : 'attend.attendHeadquartersFinal.searchTargetDateMonth',
      SEARCH_TARGET_DATE_FROM    : 'attend.attendHeadquartersFinal.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendHeadquartersFinal.searchTargetDateTo',
      SEARCH_CLOSING_DATE        : 'attend.attendHeadquartersFinal.searchClosingDate',
      SEARCH_EMPLOYMENT_ID       : 'attend.attendHeadquartersFinal.searchEmploymentId',
      SEARCH_OUTPUT_LAYOUT       : 'attend.attendHeadquartersFinal.searchOutputLayout',
      SEARCH_ORG_CODE            : 'attend.attendHeadquartersFinal.searchOrgCode',
      SEARCH_ORG_NAME            : 'attend.attendHeadquartersFinal.searchOrgName',
      SEARCH_CATEGORY            : 'attend.attendHeadquartersFinal.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.attendHeadquartersFinal.searchCategoryOption',
      SEARCH_PAGE_INDEX          : 'attend.attendHeadquartersFinal.searchPageIndex',
    },
    /** CSV出力 */
    AttendCSVOutput: {
      SEARCH_TARGET_DATE_FROM    : 'attend.attendCSVOutput.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendCSVOutput.searchTargetDateTo',
      SEARCH_ORG_CODE            : 'attend.attendCSVOutput.searchOrgCode',
      SEARCH_CATEGORY            : 'attend.attendCSVOutput.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.attendCSVOutput.searchCategoryOption',
      SEARCH_EMPLOYMENT_ID       : 'attend.attendCSVOutput.searchEmploymentId',
      SEARCH_STAFF_CODE          : 'attend.attendCSVOutput.searchStaffCode',
      SEARCH_OUTPUT_LAYOUT       : 'attend.attendCSVOutput.searchOutputLayout',
    },
    /** 歩合登録 */
    AttendIncentiveSalary: {
      SEARCH_TARGET_DATE_MONTH   : 'attend.attendIncentiveSalary.searchTargetDateMonth',
      SEARCH_TARGET_DATE_FROM    : 'attend.attendIncentiveSalary.searchTargetDateFrom',
      SEARCH_TARGET_DATE_TO      : 'attend.attendIncentiveSalary.searchTargetDateTo',
      SEARCH_ORG_CODE            : 'attend.attendIncentiveSalary.searchOrgCode',
      SEARCH_CATEGORY            : 'attend.attendIncentiveSalary.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'attend.attendIncentiveSalary.searchCategoryOption',
    },
  }
} as const;

/** Session Storage Key  */
export const SessionStorageKey = {

  /** ログインユーザー */
  LoginUser: {

    /** 企業コード */
    COMPANY_CODE: 'loginUser.companyCode',

    /** スタッフコード */
    STAFF_CODE: 'loginUser.staffCode',

    /** スタッフ名 */
    STAFF_NAME: 'loginUser.staffName',

    /** 組織コード */
    ORG_CODE: 'loginUser.orgCode',

    /** 組織名 */
    ORG_NAME: 'loginUser.orgName',

    /** メールアドレス */
    MAIL: 'loginUser.mail',

    /**
     * 権限
     * @deprecated 旧権限のため未使用
    */
    PERSONAL_ROLE: 'loginUser.personalRole',

    /**
     * 管理者権限
     * @deprecated 旧権限のため未使用
    */
    ADMINISTRATOR: 'loginUser.administrator',

    /**
     * GPS打刻使用フラグ
     * @description true : 使用する
    */
    USE_GPS_STAMP: 'loginUser.useGpsStamp',

    /**
     * 給与明細ダウンロード使用フラグ
     * @description true : 使用する
    */
    USE_SALARY_PAY_SLIP: 'loginUser.useSalaryPayslip',

    /**
     * 閲覧範囲
     * @description 0:所属組織 1:全て 2'所属と許可カテゴリ項目
    */
    ACCESS_LEVEL: 'loginUser.accessLevel',

    /**
     * レジレポートの金額修正可能フラグ
     * @deprecated 旧権限のため未使用
     * @description true : 使用する
    */
    MODIFY_REGIREPORT: 'loginUser.modifyRegireport',

    /**
     * スタッフ人件費の画面表示フラグ
     * @deprecated 旧権限のため未使用
     * @description true : 使用する
    */
    DISPLAY_LABOR_COST: 'loginUser.displayLaborCost',

    /**
     * 通知エリアタイプ
     * @description 0：本人の情報のみ表示 1：閲覧可能店舗の情報を表示
    */
    NOTIFICATION_TYPE: 'loginUser.notificationType',

    /**
     * 顔写真ファイルパス
     * @example ${domainUrl}/${loginUser.companyCode}/${loginUser.staffCode}/1.jpg?${moment()}
    */
    PHOTO_PATH: 'loginUser.photoPath',
  },

  /** 共通 */
  Common: {

    /** 権限 */
    ROLE: 'roleScreen',
    /** 組織カテゴリ絞り込み */
    ORG_FILTER_TREE_TOGGLE: (pathname: string, orgFilter?: number) => `sessionOrganizationFileterTree${pathname}${orgFilter ?? ''}.Toggle`,
    ORG_FILTER_TREE_SELECTED: (pathname: string, orgFilter?: number) => `sessionOrganizationFileterTree${pathname}${orgFilter ?? ''}.Selected`,
    SELECT_ORG_CODE: (funcName: string, orgFilter?: number) => `sessionOrgSelect/${funcName}${orgFilter ?? ''}`,

    DataTable: {

      INIT_PAGE_INDEX: (tableId: number, pathname: string) => `sessionDataTable${tableId}${pathname}.initPageIndex`,
    },

  },

  /** 損益管理 */
  Sales: {
    /** 日別店舗売上 */
    MonthlyDayStoreSalesManagement: {
      SEARCH_TARGET_DATE         : 'sales.monthlyDayStoreSalesManagement.searchTargetDate',
      SEARCH_ORG_CODE            : 'sales.monthlyDayStoreSalesManagement.searchOrgCode',
      SEARCH_CATEGORY            : 'sales.monthlyDayStoreSalesManagement.searchCategory',
      SEARCH_CATEGORY_OPTION     : 'sales.monthlyDayStoreSalesManagement.searchCategoryOption',
      SEARCH_CATEGORY_ARRAY     : 'sales.monthlyDayStoreSalesManagement.searchCategoryArray',
    },
  },

  /** 勤怠管理 */
  Attend: {

    /** 勤怠ロック */
    AttendAchievementLock: {
        SEARCH_TARGET_DATE_FROM    : 'attend.attendAchievementLock.searchTargetDateFrom',
        SEARCH_TARGET_DATE_TO      : 'attend.attendAchievementLock.searchTargetDateTo',
        SEARCH_LOCK_STATUS         : 'attend.attendAchievementLock.searchLockStatus',
        SEARCH_IS_ERROR            : 'attend.attendAchievementLock.searchIsError',
        SEARCH_IS_ALERT_OVERTIME   : 'attend.attendAchievementLock.searchIsAlertOvertime',
        SEARCH_IS_ALERT_HOLIDAYWORK: 'attend.attendAchievementLock.searchIsAlertHolidaywork',
        SEARCH_IS_DISAPPROVAL      : 'attend.attendAchievementLock.searchIsDisapproval',
        SEARCH_IS_LOCKABLE         : 'attend.attendAchievementLock.searchIsLockable',
    },
  },


  /** ユーザー設定 */
  UserSetting: {
    /**スタッフマスタ */
    StaffMaster: {
      SEARCH_ORG_STATUS          : 'userSetting.staffMaster.searchOrgStatus',
    },
    /** ユーザー登録状況確認 */
    StaffRegistrationStatus: {
      SEARCH_ORG_STATUS                     : 'userSetting.StaffRegistrationStatus.searchOrgStatus',
      SEARCH_USER_REAGISTRATION_STATUS      : 'userSetting.StaffRegistrationStatus.searchUserRegistrationStatus',
      SEARCH_FACE_REAGISTRATION_STATUS      : 'userSetting.StaffRegistrationStatus.searchFaceRegistrationStatus',
      SEARCH_EMPLOYMENT_REAGISTRATION_STATUS: 'userSetting.StaffRegistrationStatus.searchEmploymentRegistrationStatus',
    },
  },

} as const;

/** 区分値  */
export const Values = {

  /** 機能タイプ */
  FunctionType: {
    'Master'    : { label: 'マスタ',        value: 1 },
    'Attend'    : { label: '勤怠',          value: 2 },
    'Sales'     : { label: '売上',          value: 3 },
    'Dashboard' : { label: 'ダッシュボード', value: 4 },
  },

  /**
   * 店舗状態
   * ※ リストの並び順で定義
  */
  OrgStatus: {
    'Open'      : { label: '開店',     value: '0' },
    'Scheduled' : { label: '開店予定', value: '2' },
    'Closed'    : { label: '閉店',     value: '1' },
  },

  /**
   * マネージャータイプ
   * ※ リストの並び順で定義
  */
  ManagerType: {
    'Manager'       : { label: 'マネージャー',      value: '0' },
    'SeniorManager' : { label: 'シニアマネージャー', value: '1' },
  },

  /**
   * 労働形態
  */
   WorkingForm: {
    'FullTime' : { label: 'フルタイム',   value: 0 },
    'PartTime' : { label: 'パートタイム', value: 1 },
  },

  /**
   * ユーザー登録状況
  */
  UserRegistrationStatus: {
      'Unregistered' : { label: '未登録',   value: '0' },
      'Pending'      : { label: '認証待ち', value: '1' },
      'Complete'     : { label: '完了',     value: '2' },
    },

  /**
   * 時間 10進表示時の端数処理
  */
  roundMethodOfThirdDecimalPlace: {
    'RoundDown'   : { label: '切り捨て', value: '0' },
    'RoundUp'     : { label: '切り上げ', value: '1' },
    'RoundHalfUp' : { label: '四捨五入', value: '2' },
  },

  /**
   * 税込区分
  */
  TaxExclude: {
    'Excluded'   : { label: '税抜', value: '1' },
    'Included'   : { label: '税込', value: '0' },
  },


} as const;

/** 型定義・関数定義*/

/** 機能タイプ */
const functionTypeValues = Object.values(Values.FunctionType).map(o => o.value);
export type FunctionTypeType = typeof functionTypeValues[number];

/** 店舗状態 */
const orgStatusValues = Object.values(Values.OrgStatus).map(o => o.value);
export type OrgStatusType = typeof orgStatusValues[number];

/** マネージャータイプ */
const managerTypeValues = Object.values(Values.ManagerType).map(o => o.value);
export type ManagerTypeType = typeof managerTypeValues[number];

/** ユーザー登録状況 */
const userRegistrationStatusValues = Object.values(Values.UserRegistrationStatus).map(o => o.value);
export type UserRegistrationStatusType = '-1' | typeof userRegistrationStatusValues[number];

/** 税込区分 */
const taxExcludeValues = Object.values(Values.TaxExclude).map(o => o.value);
export type TaxExcludeType = typeof taxExcludeValues[number];
