import moment from 'moment';
import ApiClient from './ApiClient';

export const getMediaMst = async (companyCode: string, orgCode: string, paths: any): Promise<any> => {
  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/mediaMst/${companyCode}`, params);
  return response.data;
};

export const getMediaMstV3 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/${paths}/mediaMst/${companyCode}`, params);
  return response.data;
};

export const getMonthlyReport = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}`, params);
  return response.data;
};

export const getMonthlyReportV2 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    functionType: 3,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v2/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);

  return response.data;
};

export const getMonthlyReportV3 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    functionType: 3,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);

  return response.data;
};
export const getDiscountMonthlyListHeader = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  categoryAry: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    functionType: 0,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/discountListHeader/${companyCode}?${appendUrl}`, params);

  return response.data;
};


export const getDiscountmonthlyReportV4 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    functionType: 0,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v4/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);

  return response.data;
};

export const getDiscountListHeader = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/day/discountListHeader/${companyCode}`, params);

  return response.data;
};

export const getDaySeparateDiscountList = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/daySeparateDiscountList/${companyCode}`, params);

  return response.data;
};

export const getMonthlyReportV5 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };

  const response = await ApiClient.get(`/v5/sales/list/standard/monthly/${paths}/${companyCode}`, params);
  return response.data;
};

export const getMonthlyReportV6 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };

  const response = await ApiClient.get(`/v6/sales/list/standard/monthly/${paths}/${companyCode}`, params);
  return response.data;
};

export const getMonthlyConfirmDocSubmission = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);
  return response.data;
};
export const getItemsSalesOrderReport = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  startTime: string,
  monthlyEndDate: string,
  endTime: string,
  paths: any,
  dayOfweek: any,
  categoryAry: any,
  monthlyComparePrevYear?: any): Promise<any> => {
  const {
    itemPub, itemSat, itemSun, itemMon, itemTue, itemWed, itemTur, itemFri,
  } = dayOfweek[0];

  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const params = {
    orgCode,
    startDate: monthlyStartDate,
    startTime,
    endDate: monthlyEndDate,
    endTime,
    itemPub,
    itemSat,
    itemSun,
    itemMon,
    itemTue,
    itemWed,
    itemTur,
    itemFri,
    functionType: 3,
    loginStaffCode,
  };

  const response = await ApiClient.get(`/v2/sales/list/standard/${paths}/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getTimeListReport = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  startTime: string,
  monthlyEndDate: string,
  endTime: string,
  dayOfweek: any,
  flag: any,
  targetAggregate: any,
  taxExclude: string,
): Promise<any> => {

  const {
    timePub,
    timeSat,
    timeSun,
    timeMon,
    timeTue,
    timeWed,
    timeTur,
    timeFri,
  } = dayOfweek[0];


  const params = {
    orgCode,
    startDate: monthlyStartDate,
    startTime,
    endDate: monthlyEndDate,
    endTime,
    timePub,
    timeSat,
    timeSun,
    timeMon,
    timeTue,
    timeWed,
    timeTur,
    timeFri,
  };

  let path = '';

  if (flag === 2 && targetAggregate === 1) {
    // 商品別注文時間別 V1
    path = '/v1/sales/list/standard/ordertime/item/salesmanagemet';
  }
  if (flag === 1 && targetAggregate === 1) {
    // 日別注文時間別 V2
    path = '/v2/sales/list/standard/ordertime/salesmanagemet';
  }
  if (flag === 2 && targetAggregate === 0) {
    // 商品別会計時間別 V1
    path = '/v1/sales/list/standard/time/item/salesmanagemet';
  }
  if (flag === 1 && targetAggregate === 0) {
    // 日別会計時間別 V2
    path = '/v2/sales/list/standard/time/salesmanagemet';
  }

  // 日別の場合はパラメータに税区分を追加する
  const response = await ApiClient.get(`${path}/${companyCode}`, flag === 1 ? {...params, taxExclude} : params);
  return response.data;
};

export const getYearlyReport = async (
  companyCode: string,
  orgCode: string,
  yearlyStartDate: string,
  yearlyEndDate: string,
  paths: any,
  categoryAry: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    functionType: 3,
    loginStaffCode,
    orgCode,
    yearlyStartDate,
    yearlyEndDate,
  };

  const response = await ApiClient.get(`/v3/sales/list/standard/yearly/${paths}/${companyCode}?${appendUrl}`, params);
  return response.data;
};


export const getFiscalYear = async (companyCode: string): Promise<any> => {
  const params = {
    date: moment(new Date()).format('YYYY-MM-DD'),
  };

  const response = await ApiClient.get(`/v1/fiscalYear/targetdate/${companyCode}`, params);
  return response.data;
};

export const getBudgetPerfomanceManagement = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}?`, params);
  return response.data;
};

export const getDaySeparateBudgetPerfomanceManagementV4 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  monthlyComparePrevYear?: any,
): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };
  const response = await ApiClient.get(`/v4/sales/list/standard/monthly/${paths}/${companyCode}?`, params);
  return response.data;
};

export const getBudgetPerfomanceManagementV2 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    functionType: 3,
    loginStaffCode,
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
  };
  const response = await ApiClient.get(`/v2/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getBudgetPerfomanceManagementV3 = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry: any,
): Promise<any> => {
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    functionType: 3,
    loginStaffCode,
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
  };
  const response = await ApiClient.get(`/v3/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);
  return response.data;
};



export const downloadMonthlyReportCsv = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, monthlyReport, fileName);
};

export const downloadBudgetPerformanceManagementCsv = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, monthlyReport, fileName);
};

export const downloadMonthlyReportExcel = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, monthlyReport, fileName);
};

export default getMonthlyReport;
