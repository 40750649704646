/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import useToastNotification from 'hooks/useToastNotification';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useTimeListReport from './hooks';
import TimeDaySeparateSales from './TimeDaySeparateSales_new';
import TimeDaySeparateProducts from './TimeDaySeparateProducts_new';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { getOrgNameFromOrgTreesOptions, getTargetWeekText } from 'utility/formatUtil';
import { TaxExcludeType, Values } from 'utility/constants';

const TimeListLayout = () => {
  const datefrom = new Date(moment().subtract(1, 'days').toString());
  const dateTo = new Date(moment().toString());
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const [showTabs, setShowTabs] = useState(0);
  const [getTargetAggregate, setTargetAggregate] = useState(1);
  const [orgTreesOptions, setOrgTreesOptions] = useState([]);

  //
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');
  const [getItemStartTime, setItemStartTime] = useState('05');
  const [getItemEndTime, setItemEndTime] = useState('04');
  const { salesTimeList, productTimeList, formik, getIsLoading, setIsLoading, taxExclude, setTaxExclude  } = useTimeListReport(
    String(orgCode),
    moment(targetDateFrom).format('YYYY-MM-DD'),
    getItemStartTime,
    moment(targetDateTo).format('YYYY-MM-DD'),
    getItemEndTime,
    showTabs,
    categoryAry,
    getTargetAggregate,
  );

  const targetPeriodText = `対象期間：${moment(targetDateFrom).format('YYYY年MM月DD日')}~${moment(targetDateTo).format('YYYY年MM月DD日')}`;
  const targetStoreText = `対象店舗：${getOrgNameFromOrgTreesOptions(orgTreesOptions, orgCode)}`;
  const targetAggregateText =  `集計対象：${getTargetAggregate === 1 ? '注文時' : '会計時'}`;
  const targetWeekText = `対象曜日： ${getTargetWeekText(formik)}`;
  const targetTimeText = `対象時間：${getItemStartTime}時~${getItemEndTime}時`;
  const targetTaxExcludeText = `税区分：${Object.values(Values.TaxExclude).find(option => option.value === taxExclude)?.label}`;

  const itemStartTimeList:any[] = [];
  for (let i = 0; i < 24; i += 1) {
    itemStartTimeList.push({
      label: String(i < 10 ? `0${i}` : i),
      value: String(i < 10 ? `0${i}` : i),
    });
  }

  const targetTimeList: Array<string> = useMemo(() => {
    const tmpTargetTimeList: any[] = ["合計"];
    let st = Number(getItemStartTime);
    let ed = Number(getItemEndTime);
    if (st > ed) {
      ed += 24;
    }
    for (; st <= ed; st += 1) {
      tmpTargetTimeList.push(`${st >= 24 ? st - 24 : st}時`);
    }
    return tmpTargetTimeList;
  }, [getItemStartTime, getItemEndTime])

  const { errorNotification } = useToastNotification();

  const expandTabs = (status:number) => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setShowTabs(status);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel
              label="対象期間"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateTo(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="組織名"
          targetdayForm={targetDateFrom}
          targetdayTo={targetDateTo}
          orgOrgOptionListCallback={setOrgTreesOptions}
        />

        <div style={{ marginBottom: '15px' }}>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel label="集計対象" />
            </FlexBoxItem>
            <FlexBoxItem>
              <RadioSelectForm
                label=""
                name="targetAggregate"
                items={[
                  { label: '注文時', value: '1' },
                  { label: '会計時', value: '0' },
                ]}
                value={String(getTargetAggregate)}
                setValue={(e) => setTargetAggregate(Number(e.target.value))}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel label="対象曜日" />
          </FlexBoxItem>
          <FlexBoxItem>
            <MultipleCheckboxForm
              name="stateStatus"
              label=""
              items={[
                {
                  id: 'timePub',
                  label: '祝日',
                  value: formik.values.timePub,
                },
                {
                  id: 'timeSat',
                  label: '土曜日',
                  value: formik.values.timeSat,
                },
                {
                  id: 'timeSun',
                  label: '日曜日',
                  value: formik.values.timeSun,
                },
                {
                  id: 'timeMon',
                  label: '月曜日',
                  value: formik.values.timeMon,
                },
                {
                  id: 'timeTue',
                  label: '火曜日',
                  value: formik.values.timeTue,
                },
                {
                  id: 'timeWed',
                  label: '水曜日',
                  value: formik.values.timeWed,
                },
                {
                  id: 'timeTur',
                  label: '木曜日',
                  value: formik.values.timeTur,
                },
                {
                  id: 'timeFri',
                  label: '金曜日',
                  value: formik.values.timeFri,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FlexBoxItem>
        </FlexBox>

        <div style={{
          display: 'flex', alignItems: 'center', marginTop: '15px', fontSize: '14px',
        }}
        >
          <span style={{ marginRight: '50px' }}>対象時間</span>
          <span style={{ width: '100px' }}>
            <VerticalLabelSelectForm
              label=""
              name="itemStartTime"
              value={String(getItemStartTime)}
              setValue={
                (val) => setItemStartTime(val)
              }
              options={itemStartTimeList}
            />
          </span>
          <span style={{ marginLeft: '5px' }}>時</span>
          <span style={{ margin: '0 10px' }}>～</span>
          <span style={{ width: '100px' }}>
            <VerticalLabelSelectForm
              label=""
              name="itemEndTime"
              value={String(getItemEndTime)}
              setValue={(val) => setItemEndTime(val)}
              options={itemStartTimeList}
            />
          </span>
          <span style={{ marginLeft: '5px' }}>時</span>
        </div>

        <FormField>
          <FormSubmitArea>
            <div style={{ display: 'flex', marginTop: '0px' }}>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="日別売上"
                  onClick={() => expandTabs(1)}
                  ghost={showTabs !== 1}
                />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="商品別"
                  onClick={() => expandTabs(2)}
                  ghost={showTabs !== 2}
                />
              </div>
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          {showTabs === 1
           && (
            <>
              <FlexBox>
                <FlexBoxItem width="100px">
                  <FormLabel label="税区分" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <RadioSelectForm
                    name="taxExclude"
                    label=""
                    items={Object.values(Values.TaxExclude)}
                    value={String(taxExclude)}
                    setValue={(e) => {
                      setTaxExclude(e.target.value as TaxExcludeType);
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
              <div style={{ marginTop: '15px' }}>
                <div className="row">
                  <BlockUI blocking={getIsLoading}>
                    <TimeDaySeparateSales
                      setIsLoading={setIsLoading}
                      salesTimeList={salesTimeList}
                      targetTimeList={targetTimeList}
                      targetPeriodText={targetPeriodText}
                      targetStoreText={targetStoreText}
                      targetAggregateText={targetAggregateText}
                      targetWeekText={targetWeekText}
                      targetTimeText={targetTimeText}
                      targetTaxExcludeText={targetTaxExcludeText}
                      roleScreen={roleScreen}
                    />
                  </BlockUI>
                </div>
              </div>
           </>)}
          {showTabs === 2
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <TimeDaySeparateProducts
                   setIsLoading={setIsLoading}
                   productTimeList={productTimeList}
                   targetTimeList={targetTimeList}
                   targetPeriodText={targetPeriodText}
                   targetStoreText={targetStoreText}
                   targetAggregateText={targetAggregateText}
                   targetWeekText={targetWeekText}
                   targetTimeText={targetTimeText}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>
      </FormContents>
    </div>
  );
};

export default TimeListLayout;
