import { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import TimeDaySeparateSalesReportItemDomain, { TimeDaySeparateSalesReport, TimeDaySeparateSalesReportItem } from 'domain/master/storeManagement/TimeDaySeparateSalesReportItem';
import TimeProductReportItemDomain, { TimeProductReportItem, TimeProductReport } from 'domain/master/storeManagement/TimeProductReportItem';
import useToastNotification from 'hooks/useToastNotification';
import { LocalStorageKey, TaxExcludeType, Values } from 'utility/constants';

const useTimeListReport = (
  orgCode: string,
  monthlyStartDate: string,
  startTime:string,
  monthlyEndDate: string,
  endTime:string,
  flag:number,
  categoryAry: any,
  getTargetAggregate: number,
) => {
  const [productTimeList, setProductTimeList] = useState<Array<TimeProductReport>>([]);
  const [salesTimeList, setSalesTimeList] = useState<Array<TimeDaySeparateSalesReport>>([]);
  // 税区分
  const [taxExclude, setTaxExclude] = useState<TaxExcludeType>(() => {
    if (localStorage.getItem(LocalStorageKey.Sales.TimeList.SEARCH_TAX_EXCLUDE)) {
      return localStorage.getItem(LocalStorageKey.Sales.TimeList.SEARCH_TAX_EXCLUDE) as TaxExcludeType;
    }
    return Values.TaxExclude.Excluded.value;
  });

  const onSubmit = () => {};
  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    const dateOfweek = [
      {
        timePub: formik.values.timePub,
        timeSat: formik.values.timeSat,
        timeSun: formik.values.timeSun,
        timeMon: formik.values.timeMon,
        timeTue: formik.values.timeTue,
        timeWed: formik.values.timeWed,
        timeTur: formik.values.timeTur,
        timeFri: formik.values.timeFri,
      },
    ];
    setIsLoading(true);
    api.getTimeListReport(
      companyCode,
      orgCode,
      monthlyStartDate,
      startTime,
      monthlyEndDate,
      endTime,
      dateOfweek,
      flag,
      getTargetAggregate,
      String(taxExclude),
    ).then((response: any) => {
      if (isSubscribed) {
        if (flag === 1) {
          setSalesTimeList(response.map((r: any) => ({ targetDay: r.targetDay, dispTargetDay: moment(r.targetDay, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）'), items: r.items?.map((item: TimeDaySeparateSalesReportItem) => new TimeDaySeparateSalesReportItemDomain(item)) || [] })))
        }
        if (flag === 2) {
          setProductTimeList(response.map((r: any) => ({ menuCode: r.menuCode, menuName: r.menuName, unitPrice: r.unitPrice, items: r.items?.map((item: TimeProductReportItem) => new TimeProductReportItemDomain(item)) || [] })))
        }
      }
      setIsLoading(false);
    });
    return () => { isSubscribed = false; };
  }, [startTime, endTime, monthlyStartDate, monthlyEndDate, formik.values.timePub, formik.values.timeSat, formik.values.timeSun, formik.values.timeMon, formik.values.timeTue, formik.values.timeWed, formik.values.timeTur, formik.values.timeFri, orgCode, flag, formik.values, categoryAry, getTargetAggregate, taxExclude]);

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.Sales.TimeList.SEARCH_TAX_EXCLUDE, String(taxExclude));
  }, [taxExclude]);

  return {
    salesTimeList, productTimeList, formik, getIsLoading, setIsLoading, taxExclude, setTaxExclude
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();

  const downloadMonthlyReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '時間別日別売上.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });

  };

  const downloadMonthlyReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '時間別日別売上.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadCntOrderReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '商品時間別.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadCntOrderReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '商品時間別.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadCntOrderReportCsv,
    downloadCntOrderReportExcel,
  };
};

export default useTimeListReport;
